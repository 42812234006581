import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { verifyUser } from "../lib/auth/verify-user"
import { useAuth } from "../providers/auth/AuthProvider"
import { Button } from "../components/button"

import Banner from "../components/banner"
import Layout from "../components/layout"
import PageLoader from "../components/page-loader"
import PortableText from "../components/portable-text"
import Seo from "../components/seo"

export default function FellowshipTemplate({ data }) {
  const { fellowship } = data
  const { getIdTokenClaims } = useAuth0()

  const [loading, setLoading] = useState(true)
  const [showMessage, setMessage] = useState(false)
  const [showErrMessage, setErrMessage] = useState(false)
  const [state] = useAuth()

  useEffect(() => {
    if (typeof window !== "undefined" && fellowship.isRestrictedContent) {
      if (!state.app_metadata) {
        async function getUserData() {
          try {
            const claims = await getIdTokenClaims()
            if (!claims) {
              setMessage(true)
              setLoading(false)
            } else {
              const idToken = claims.__raw
              const res = await verifyUser({ sub: claims.sub, token: idToken })

              if (res && res.data) {
                if (
                  fellowship.userRole.userSlug.current !== "applicant" &&
                  res.data.app_metadata.account_type === "free"
                ) {
                  setMessage(true)
                  setLoading(false)
                } else {
                  setLoading(false)
                }
              } else {
                console.error("getUserData() issue with res", res)
                setErrMessage(true)
                setLoading(false)
              }
            }
          } catch (err) {
            console.error("getUserData() error", err)
            setErrMessage(true)
            setLoading(false)
          }
        }

        getUserData()
      } else {
        if (
          fellowship.userRole.userSlug.current !== "applicant" &&
          state.app_metadata.account_type === "free"
        ) {
          alert("Not the right account")
        } else {
          setMessage(true)
          setLoading(false)
        }
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <Layout>
      <Seo title={fellowship.description} />

      {typeof window !== "undefined" && loading ? (
        <PageLoader />
      ) : (
        <>
          {showMessage && (
            <div className="w-1/2 mx-auto flex flex-col justify-center items-center pt-16">
              <p className="text-3xl text-gray-600 text-center leading-snug mb-6">
                You must be logged in with a valid BSSR Membership to view this
                page.
              </p>

              <Button href="/login">Click Here to Sign In</Button>
            </div>
          )}

          {showErrMessage && (
            <div className="w-1/2 mx-auto flex flex-col justify-center items-center pt-16">
              <p className="text-3xl text-gray-600 text-center leading-snug">
                There was a problem loading this page. Please try again.
              </p>
            </div>
          )}

          {!showMessage && !showErrMessage && (
            <>
              <Banner label="Fellowship">{fellowship.description}</Banner>

              <main className="container mx-auto">
                <div className="px-3 py-3 lg:py-12 lg:flex items-start">
                  <button
                    id="subNav"
                    className="lg:hidden py-1 text-lg font-semibold text-indigo-800 hover:text-indigo-600 flex items-center"
                  >
                    <span className="pr-2">{fellowship.description}</span>
                    <svg
                      id="arrowDown"
                      className="w-4 mt-1 fill-current"
                      version="1.1"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m2.5 31.301c0-2.6016 1-5.1016 2.8984-7.1016 3.8984-3.8984 10.301-3.8984 14.199 0l30.402 30.301 30.398-30.398c3.8984-3.8984 10.301-3.8984 14.199 0 3.8984 3.8984 3.8984 10.301 0 14.199l-37.496 37.5c-1.8984 1.8984-4.3984 2.8984-7.1016 2.8984-2.6992 0-5.1992-1.1016-7.1016-2.8984l-37.5-37.402c-1.8984-2-2.8984-4.5977-2.8984-7.0977z" />
                    </svg>
                    <svg
                      id="arrowUp"
                      className="hidden w-4 mt-1 fill-current"
                      version="1.1"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m97.5 68.699c0 2.6016-1 5.1016-2.8984 7.1016-3.8984 3.8984-10.301 3.8984-14.199 0l-30.402-30.301-30.398 30.301c-3.8984 3.8984-10.301 3.8984-14.199 0-3.8984-3.8984-3.8984-10.301 0-14.199l37.5-37.5c1.8984-1.8984 4.3984-2.8984 7.1016-2.8984 2.6992 0 5.1992 1.1016 7.1016 2.8984l37.5 37.5c1.8945 2 2.8945 4.5977 2.8945 7.0977z" />
                    </svg>
                  </button>

                  <article className="leading-loose w-full max-w-3xl xl:text-lg">
                    <h2 className="text-2xl lg:text-4xl font-medium mt-0 mb-8">
                      Information
                    </h2>

                    <PortableText blocks={fellowship._rawContent} />
                  </article>

                  <aside
                    id="sectionMenu"
                    className="hidden lg:block lg:sticky self-start top-0 ml-12"
                    style={{ width: 300 }}
                  >
                    <div className="lg:my-3">
                      <p className="hidden lg:block lg:text-xl font-semibold text-gray-800 border-b-4 border-gray-200 mb-6">
                        More Information
                      </p>
                      <ul className="mb-4">
                        <li>
                          <b>Start Date:</b> {fellowship.startingDate}
                        </li>
                        <li>
                          <b>Term / Duration:</b> {fellowship.term}
                        </li>
                      </ul>
                      <p>
                        For more information about this session please contact{" "}
                        {fellowship.contact} by email on{" "}
                        {fellowship.contactEmail}
                      </p>
                    </div>
                  </aside>
                </div>
              </main>
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    fellowship: sanityFellowship(id: { eq: $id }) {
      description
      location {
        lat
        lng
      }
      startingDate(formatString: "do MMMM YYYY")
      term
      contact
      contactEmail
      isRestrictedContent
      userRole {
        userSlug {
          current
        }
      }
      _rawContent
    }
  }
`
